<!--
	Quick version:
		Should allow passing a modelField or model + fieldNamePath
		Should allow clearing and triggering event
		Cleanup using v-on & v-attrs, to support more props and rem need for click/focus/input/change
	Could check to pimp w https://v2.vuetifyjs.com/en/components/date-pickers/#dialog-and-menu
-->
<template>
	<div class="br-field-db-date-picker">
		<br-field-db :field="field" :disabled="disabled" hide-details :clearable="clearable" no-label as="input" input-type="text" readonly icon3="mdi-calendar|black" @click="focus()" @focus="focus()" />
			<!-- IMPORTANT: Need to have as="input" & input-type="text", otherwise will if automatically becomes type="date", focus & blur events will start fucking -->
		<v-dialog :dark="theme==='dark'" :light="theme==='light'" :value="showPicker" width="auto" @input="close($event)">
			<v-date-picker :value="field.val" :allowed-dates="allowedDates_Ymd?allowedDates_eval:null" :events="events?events_eval:null" :min="min_Ymd" :max="max_Ymd" @change="set($event)" class="mt-4 br-field-db-date-picker--dialog">
				<template v-if="$slots.footer" #default>
					<slot name="footer" />
				</template>
			</v-date-picker>
		</v-dialog>
	</div>
</template>

<script>
	
	import { B_REST_Utils } from "../../../../classes";
	
	const DATE_MAX_EVENT_COUNT = 3; //How many event dots to display max on a date
	
	
	
	export default {
		props: {
			field:        {type:undefined, required:true},
			disabled:     {type:Boolean,   default:null},
			clearable:    {type:Boolean,   default:null},
			events:       {type:Array,     default:null},  //Arr of {date:<Date>, color}
			eventsOnly:   {type:Boolean,   default:false}, //If true, then will be equivalent of setting allowedDates to events's dates
			allowedDates: {type:Array,     default:null},  //Arr of Date instances
			min:          {type:Date,      default:null},
			max:          {type:Date,      default:null},
		},
		watch: {
			events()       { this._eventsAndAllowedDates_setup(); },
			eventsOnly()   { this._eventsAndAllowedDates_setup(); },
			allowedDates() { this._eventsAndAllowedDates_setup(); },
		},
		data()
		{
			return {
				theme:            null, //dark | light
				showPicker:       false,
				events_formatted: null, //Map of Ymd => arr of colors
				allowedDates_Ymd: null, //Arr of Ymd strings
			};
		},
		created() { this._eventsAndAllowedDates_setup(); },
		mounted() { this.theme = this.$bREST.dom_getClosestTheme_component(this); },
		computed: {
			min_Ymd() { return this.min ? B_REST_Utils.dt_toYmd(this.min) : null; },
			max_Ymd() { return this.max ? B_REST_Utils.dt_toYmd(this.max) : null; },
		},
		methods: {
			//MAIN LOGIC
				async focus()
				{
					document.activeElement.blur(); //Req: Or when we'll close the popup, it'll open again
					this.showPicker = true;
				},
				async set($event)
				{
					this.field.val  = $event;
					this.showPicker = false;
					this.$emit('input', $event);
					this.$emit('change',$event);
				},
				async close() { this.showPicker = false; },
			//CONDITIONAL DATES RELATED
				_eventsAndAllowedDates_setup()
				{
					let events_formatted = null;
					let allowedDates_Ymd = null;
					
					if (this.events)
					{
						events_formatted = {};
						
						const struct = {
							date:  {accept:[Date],   required:true},
							color: {accept:[String], required:true},
						};
						const struct_suffix = "BrFieldDbDatePicker::_events_setup()";
						for (const loop_event of this.events)
						{
							B_REST_Utils.object_hasValidStruct_assert(loop_event, struct, struct_suffix);
							const loop_Ymd = B_REST_Utils.dt_toYmd(loop_event.date);
							if (!B_REST_Utils.object_hasPropName(events_formatted,loop_Ymd)) { events_formatted[loop_Ymd]=[]; }
							const loop_dateColors = events_formatted[loop_Ymd];
							if (loop_dateColors.length<DATE_MAX_EVENT_COUNT) { loop_dateColors.push(loop_event.color); }
						}
					}
					
					if      (this.eventsOnly && this.events) { allowedDates_Ymd = Object.keys(events_formatted);                                }
					else if (this.allowedDates)              { allowedDates_Ymd = this.allowedDates.map(loop_d=>B_REST_Utils.dt_toYmd(loop_d)); } //Throws if was already a string
					
					this.events_formatted = events_formatted;
					this.allowedDates_Ymd = allowedDates_Ymd;
				},
				events_eval(Ymd)       { return this.events_formatted[Ymd] ?? false; },
				allowedDates_eval(Ymd) { return this.allowedDates_Ymd.includes(Ymd); },
		},
	};
	
</script>

<style scoped>
	/* WARNING: Don't put scoped, or .v-date-picker-table selector doesn't work, even w :deep()... */
	
	.br-field-db-date-picker--dialog:deep(.v-date-picker-table) {
		height: 216px !important;
	}
	
</style>