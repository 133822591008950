<template>
	<br-generic-form-base :derived-component="_self">
	
		<template #title>
			<span>{{ title }}</span>
			<template v-if="owner_fk && !model.isNew">
				<v-spacer />
				<span>
					#{{ owner_fk }} {{ owner_name }}
					<v-btn color="primary" @click="$bREST.routes_goBlank_moduleForm_pkTag('citizen',owner_fk)" class="ml-2" small><v-icon>mdi-folder</v-icon></v-btn>
				</span>
			</template>
		</template>
	
		<template #fields>
			<v-alert type="error" v-if="show_dueRenewal"> 
				{{ t('licenceRenewalDue') }}
			</v-alert>
			<v-alert type="info" v-if="show_unreadNotes"> 
				{{ t('unreadNotes') }}
			</v-alert>
			<v-expansion-panels :value="0" accordion>
				
				<!-- Main info -->
				<v-expansion-panel> <v-expansion-panel-header>{{ t('file') }}</v-expansion-panel-header> <v-expansion-panel-content>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="pk" disabled /> </v-col>
						<v-col v-if="mustPickCitizen" cols="12" md="6"> <fk-picker-w-view-add-btns :model="model" field="owner_fk" picker="citizenList" moduleName="citizen" /> </v-col>
						<v-col cols="12" md="6"> 
							<v-row class="d-flex align-center">
								<v-col cols="6"><br-field-db :model="model" field="medal_number" disabled /></v-col>
								<v-col cols="6"><v-btn :disabled="model.isNew" color="primary" block @click="on_medalHistory_click">{{ t('history') }}</v-btn></v-col>
							</v-row> 
						</v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="name" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="specie" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="main_breed_fk" :items="breedList" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="cross_breed_fk" :items="breedList" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="hair_color_primary_fk" items="hairColorList" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="hair_color_secondary_fk" items="hairColorList" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="sex" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="birth_date" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="weight" suffix="kg" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="microchip" /> </v-col>
						
						<!-- Passeport animal asked to move these away from the "Status" & "Characteristics" panels, and SPAD is OK w it -->
							<v-col cols="12"><v-divider /></v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" light field="is_vaccinated" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="vaccines_date" /> </v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="vaccines_date_booster" /> </v-col>
							<v-col cols="12"><v-divider /></v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" light field="is_vermifuged" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="vermifuge_date" /> </v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="vermifuge_date_booster" /> </v-col>
							<v-col cols="12"><v-divider /></v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" light field="is_vaccinated_rabies" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="vaccines_date_rabies" /> </v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="vaccines_date_rabies_booster" /> </v-col>
							<v-col cols="12"><v-divider /></v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" light field="is_neutered" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="sterilisation_date" /> </v-col>
							<v-col cols="12"><v-divider /></v-col>
							<v-col cols="12" md="4"> <br-field-db :model="model" field="origin" /> </v-col>
							<v-col cols="12" md="8"> <br-field-db :model="model" field="declaration" /> </v-col>
					</v-row>
				</v-expansion-panel-content> </v-expansion-panel>
				
				<!-- Veterinary -->
				<v-expansion-panel> <v-expansion-panel-header>{{ t('veterinary') }}</v-expansion-panel-header> <v-expansion-panel-content>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="vet_name" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="vet_phone" @change="on_vet_phone_change()" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="vet_file_number" /> </v-col>
					</v-row>
				</v-expansion-panel-content> </v-expansion-panel>
				
				<!-- Location -->
				<v-expansion-panel> <v-expansion-panel-header>{{ t('location') }}</v-expansion-panel-header> <v-expansion-panel-content>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="current_location" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="current_location_date" /> </v-col>
					</v-row>
				</v-expansion-panel-content> </v-expansion-panel>
				
				<!-- Status -->
				<v-expansion-panel> <v-expansion-panel-header>{{ t('status.title') }}</v-expansion-panel-header> <v-expansion-panel-content>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="current_status" :label="t('status.label')" :hint="t('status.hint')" persistent-hint  /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="current_status_date" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="license_cost_type" @change="on_license_cost_type_change" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db v-if="show_license_cost" :model="model" field="license_cost" /> </v-col>
					</v-row>
				</v-expansion-panel-content> </v-expansion-panel>
				
				<!-- Characteristics -->
				<v-expansion-panel> <v-expansion-panel-header>{{ t('characteristics') }}</v-expansion-panel-header> <v-expansion-panel-content>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="dhpp_prc" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="collar_color" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="leash_color" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="hair_length" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="tail_length" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="ear_style" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="cleanliness" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_child_friendly" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_indoor" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_outdoor" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_dog_friendly" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_cat_friendly" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_sick" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_injured" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="has_biting_history" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_forbidden" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6" v-if="show_declawed"> <br-field-db :model="model" light field="declawed" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12"> <br-field-db :model="model" light field="is_csrm" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12"><v-divider /></v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_dangerous" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6" v-if="show_dangerous_date"> <br-field-db :model="model" light field="dangerous_date"/> </v-col>
						<v-divider />
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_evaluated" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6" v-if="show_evaluation_date"> <br-field-db :model="model" light field="evaluation_date"/> </v-col>
						<v-col cols="12" md="6" v-if="show_restricted"> <br-field-db :model="model" light field="is_restricted" as="checkbox" @change="on_restricted_change"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="is_euthanasia" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6" v-if="show_euthanasia_date"> <br-field-db :model="model" field="euthanasia_date" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light field="report_received" as="radioGroup" radioGroupOrientation="horizontal"/> </v-col>
						<v-col cols="12" md="6" v-if="show_report_received_date"> <br-field-db :model="model" field="report_received_date" /> </v-col>
						<br-prompt :instance="restrictedPrompt"/>
					</v-row>
				</v-expansion-panel-content> </v-expansion-panel>
				
				<!-- Files -->
				<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.files.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.files">
					<br-field-file :model="model" field="photo" @change="model_isNew?undefined:awaitUnsavedChangesSaved_filesOnly()" />
					<br-field-file :model="model" field="files" @change="model_isNew?undefined:awaitUnsavedChangesSaved_filesOnly()" />
				</v-expansion-panel-content> </v-expansion-panel>
				
				<!-- Notes -->
				<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.notes.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="true">
					<notes-list :note-list-field="model.select('notes')" />
				</v-expansion-panel-content> </v-expansion-panel>
				
			</v-expansion-panels>
		</template>
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action } from "@/bREST/core/implementations/vue/vuetifyComponents/prompt/B_REST_Vuetify_Prompt.js";
	
	//If when model is new-ed or done loading (existing pk), if we should also load the tabs content or defer until we open them for the 1st time
	const LOAD_ON_MOUNT_TABS = {
		files: false,
	};
	
	export default {
		name: "animalForm",
		components: {
			NotesList:            () => import("@/custom/components/NotesList.vue"),
			FkPickerWViewAddBtns: () => import("@/custom/components/FkPickerWViewAddBtns.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "Animal",
			apiBaseUrl:         "/animals/",
			showValidationErrs: true,
			showSkeletonLoader: true,
		  //autoUpdateInterval: 2000,
			requiredFields:     "<all>|owner(first_name|last_name)",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()
			{
				if (this.model.isNew)
				{
					const citizen = this.$bREST.routes_current_pathVars_parent_pkTag; //Can yield NULL. For when route is like /citizens/:citizen/animals/:pkTag
					
					if (citizen) { this.model.select("owner_fk").val=citizen; }
					else         { this.mustPickCitizen=true;                 }
				}
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		data()
		{
			return { 
				LOAD_ON_MOUNT_TABS,
				restrictedPrompt: null,  //Instance of B_REST_Vuetify_Prompt
				mustPickCitizen:  false, //For when we create a new animal, but not going via a citizen, like "/animals/*" instead of "/citizens/123/animals/*"
			};
		},
		computed: {
			owner_fk()
			{
				const modelField = this.model.select("owner_fk");
				return modelField.isNotEmpty_andValid ? modelField.val : null;
			},
			owner_name()
			{
				const owner = this.model.select("owner");
				if (!owner) { return null; }
				return owner.select_nonEmptyValsConcatenated("first_name|last_name");
			},
			show_license_cost() { return this.model.select("license_cost_type").val===this.$bREST.consts.animalLicenseCostTypes.AMOUNT; },
			show_declawed() { return this.model.select('specie').val == this.$bREST.consts.animalSpecies.CAT },
			show_restricted() { return this.model.select('specie').val == this.$bREST.consts.animalSpecies.DOG },
			show_dangerous_date() { return this.model.select('is_dangerous').val },
			show_evaluation_date() { return this.model.select('is_evaluated').val },
			show_euthanasia_date() { return this.model.select('is_euthanasia').val },
			show_report_received_date() { return this.model.select('report_received').val == this.$bREST.consts.animalReportReceived.DOCTOR || this.model.select('report_received').val == this.$bREST.consts.animalReportReceived.VETERINARY_DOCTOR },
			show_dueRenewal() { return this.model?.select('calc_hasDueRenewal').val; },
			show_unreadNotes() { return this.model?.select('calc_hasUnreadNotes').val; },
			breedList()
			{
				switch (this.model.select('specie').val)
				{
					case this.$bREST.consts.animalSpecies.DOG:
						return 'dogBreedList';
					case this.$bREST.consts.animalSpecies.CAT:
						return 'catBreedList';
					case this.$bREST.consts.animalSpecies.OTHER:
						return 'otherBreedList';
					default:
						return [];
				}
			},
		},
		methods: {
			on_medalHistory_click()
			{
				this.$bREST.routes_goBlank_name("animalMedal-list", {animal:this.model.pk});
			},
			on_vet_phone_change()
			{
				const orig             = this.model.select("vet_phone").val;
				const formattedOrFalse = this.$bREST.format_phone_number(orig);
				if (formattedOrFalse===false) { return; }
				
				this.model.select("vet_phone").val = formattedOrFalse;
			},
			on_license_cost_type_change()
			{
				if (!this.show_license_cost) { this.model.select("license_cost").val=0; }
			},
			async on_restricted_change(modelField)
			{
				if (modelField.val)
				{
					if (!this.restrictedPrompt)
					{
						this.restrictedPrompt = new B_REST_Vuetify_Prompt(this.t("restrictedPrompt.title"), false/*isModal*/);
						
						this.restrictedPrompt.actions = [
							new B_REST_Vuetify_Prompt_Action("cancel", this.t("restrictedPrompt.cancel"), "error"),
							null,
							new B_REST_Vuetify_Prompt_Action("ok", this.t("restrictedPrompt.ok"), "primary"),
						];
						
						this.restrictedPrompt.body = this.t("restrictedPrompt.body");
					}
					
					const result = await this.restrictedPrompt.show();
					if (result!=="ok") { modelField.val=false; }
				}
			},
		},
	}
	
</script>