<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-card>
				<!-- Status -->
				<v-card-title>{{ t('status') }}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="pk" disabled /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="createdDT" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="created_by_fk" items="employeeList" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="assigned_to_fk" items="employeeList" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="vehicle_fk" items="vehicleList" items-label-name="name"/> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="planned_date" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="planned_order" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="completion_date" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="signature_date" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="reasons" multiple as="autocomplete" items="interventionReasonList" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light radioGroupOrientation="horizontal" field="status" as="radioGroup" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" light radioGroupOrientation="horizontal" field="priority" as="radioGroup" /> </v-col>
					</v-row>
				</v-card-text>	
				
				<v-divider />
				
				<!-- External -->
				<v-card-title>{{ t('external') }}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="referral_dt" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="referral_origin" /> </v-col>
						<v-expand-transition>
							<v-col cols="12" v-if="referral_origin_isOther"> <br-field-db :model="model" field="ref_description" as="textarea"/> </v-col>
						</v-expand-transition>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-title>{{ t('plaintiffAndLocation') }}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="plaintiff_type" @change="plaintiff_type_onChange()" /> </v-col>
						<v-col cols="12" md="6" v-if="plaintiff_type_isCitizen">
							<fk-picker-w-view-add-btns :model="model" field="plaintiff_fk" picker="citizenList" @picker:select="plaintiff_onSelect" moduleName="citizen" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="location_ref" @change="location_ref_onChange()" /> </v-col>
						<v-col cols="12" md="6" v-if="location_ref_isOtherCitizen">
							<fk-picker-w-view-add-btns :model="model" field="recipient_fk" picker="citizenList" @picker:select="recipient_onSelect" moduleName="citizen" />
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-title>{{ t('concernedAnimal') }}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" lg="6">
							<fk-picker-w-view-add-btns :model="model" field="animal_fk" picker="animalList" :pickerOptions="animal_pickerOptions" @picker:select="animal_onSelect" moduleName="animal" :parent_moduleName="animal_fk_parent_moduleName" :parent_pkTag="animal_fk_parent_pkTag" />
						</v-col>
						<v-col cols="12" lg="6">
							<br-field-db :model="model" field="animal_description" as="textarea" />
						</v-col>
					</v-row>
				</v-card-text>	
				
				<v-divider />
				
				<!-- Location -->
				<v-card-title>{{ t('location') }}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12"> <br-field-db :model="model" field="location_type" /> </v-col>
						<template v-if="showCoordinates">
							<v-col cols="3"> <br-field-db :model="model" field="coordinates.street_number" /></v-col>
							<v-col cols="7"> <br-field-db :model="model" field="coordinates.municipality_street_fk" picker="municipalityStreetList" :pickerOptions="street_pickerOptions" /> </v-col>
							<v-col cols="2"> <br-field-db :model="model" field="coordinates.appt_number" /> </v-col>
							<v-col cols="12" md="6"> <br-field-db :model="model" field="coordinates.municipality_fk" as="select" items="municipalityList" /> </v-col>
							<v-col cols="12" md="6"> <br-field-db :model="model" field="coordinates.postal_code" /> </v-col>
							<v-col cols="12" md="6"> <br-field-db :model="model" field="coordinates.description" as="textarea" /> </v-col>
						</template>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="distance_in_km" /> </v-col>
					</v-row>
					<v-divider />
					<v-row>
						<v-col cols="12">        <br-field-db :model="model" field="description" as="textarea" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="signature_date" /> </v-col>
					</v-row>
				</v-card-text>	
				
				<v-divider />
				
				<!-- files -->
				<div>
					<v-card-title>{{ t("tabs.files.title") }}</v-card-title>
					<v-card-text>
						<br-field-file :model="model" field="files" @change="model_isNew?undefined:awaitUnsavedChangesSaved_filesOnly()" />
					</v-card-text>
				</div>
				
				<v-divider />
				
				<!-- notes -->
				<div>
					<v-card-title>{{ t("tabs.notes.title") }}</v-card-title>
					<v-card-text>
						<notes-list :note-list-field="model.select('notes')" />
					</v-card-text>
				</div>
			</v-card>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	export default {
		name: "interventionForm",
		components: {
			NotesList:            () => import("@/custom/components/NotesList.vue"),
			FkPickerWViewAddBtns: () => import("@/custom/components/FkPickerWViewAddBtns.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "Intervention",
			apiBaseUrl:         "/interventions/",
			showValidationErrs: true,
			showSkeletonLoader: true,
		  //autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()
			{
				this.$bREST.utils.console_todo([
					`How to refer to coord.address when coords is the one that points towards the intervention ?`,
				]);
				
				if (this.model.isNew)
				{
					this.model.select("priority").val = this.$bREST.consts.interventionPriorities.EVENTUALLY;
					
					const citizen = this.$bREST.routes_current_pathVars_parent_pkTag; //Can yield NULL. For when route is like /citizens/:citizen/interventions/:pkTag
					if (citizen)
					{
						this.model.select("plaintiff_type").val = this.$bREST.consts.interventionPlaintiffTypes.CITIZEN;
						this.model.select("plaintiff_fk").val   = citizen; //WARNING: If we change this, will have impacts in server's RouteParser_Citizens::listInterventions()
						this.model.select("location_ref").val   = this.$bREST.consts.interventionLocationRefs.LOCATION_REF_PLAINTIFF;
					}
				}
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		data()
		{
			return { 
				citizenHover: false
			};
		},
		computed: {
			referral_origin_isOther()     { return this.model.select('referral_origin').val===this.$bREST.consts.interventionReferralOrigins.OTHER; },
			plaintiff_type_isCitizen()    { return this.model.select('plaintiff_type').val ===this.$bREST.consts.interventionPlaintiffTypes.CITIZEN; },
			location_ref_isOtherCitizen() { return this.model.select('location_ref').val   ===this.$bREST.consts.interventionLocationRefs.OTHER_CITIZEN; },
			location_ref_isPlaintiff()    { return this.model.select('location_ref').val   ===this.$bREST.consts.interventionLocationRefs.LOCATION_REF_PLAINTIFF; },
			// Show coordinates form for "dans le voisinage" location type only
			showCoordinates() { return this.model.select('location_type').val == this.$bREST.consts.interventionLocationTypes.NEIGHBORHOOD; },
			//Decide on which citizen field to use to link animals to (creating & loading). By default, always based on the plaintiff, except if "Lieu à visiter = Autre citoyen"
			animalOwnerFieldToUse_plaintiff_fk_VS_recipient_fk() { return !this.location_ref_isOtherCitizen ? "plaintiff_fk" : "recipient_fk"; },
			animal_pickerOptions()
			{
				return {
					onPrompt_setFilters: ({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions}) =>
					{
						const citizen_fk_or_null = this.model.select(this.animalOwnerFieldToUse_plaintiff_fk_VS_recipient_fk).val;
						picker_modelList_searchOptions.f_equalOrIN("owner_fk",/*throwIfExists*/false).valOrArr = citizen_fk_or_null;
							//IMPORTANT: Won't work if the bound BrGenericList has a filter pointing on the same fieldNamePath; the picker's filter val in the UI will overwrite the above
							//Also, sometimes doesn't work on the 1st shot and we have to close the picker & reopen. Prolly a B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING bug
					},
				};
			},
			street_pickerOptions()
			{
				return {
					onPrompt_setFilters: ({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions}) =>
					{
						picker_modelList_searchOptions.f_equalOrIN("municipality_fk",/*throwIfExists*/false).valOrArr = this.model.select("coordinates.municipality_fk").val;
							//IMPORTANT: Won't work if the bound BrGenericList has a filter pointing on the same fieldNamePath; the picker's filter val in the UI will overwrite the above
							//Also, sometimes doesn't work on the 1st shot and we have to close the picker & reopen. Prolly a B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING bug
					},
				};
			},
			animal_fk_parent_moduleName() { return this.animal_fk_parent_pkTag ? 'citizen' : null; },
			animal_fk_parent_pkTag() { return this.model.select(this.animalOwnerFieldToUse_plaintiff_fk_VS_recipient_fk).val; },
		},
		methods: {
			plaintiff_onSelect({modelField,selection})
			{
				this.$bREST.utils.console_todo([`TODO`]);
			},
			recipient_onSelect({modelField,selection})
			{
				this.$bREST.utils.console_todo([`TODO`]);
			},
			//IMPORTANT: The same logic in animal_onSelect and animal_pickerOptions()::onPrompt_setFilters
			async animal_onSelect({modelField,selection})
			{
				const {owner_fk,owner_label} = await this.$bREST.interrelatedPickers_getInfo("animal",selection.pk);
				this.model.select(this.animalOwnerFieldToUse_plaintiff_fk_VS_recipient_fk).val = owner_fk; //NOTE: Can have no owner
			},
			plaintiff_type_onChange() { if (!this.plaintiff_type_isCitizen)    { this.model.select("plaintiff_fk").clear(); } },
			location_ref_onChange()   { if (!this.location_ref_isOtherCitizen) { this.model.select("recipient_fk").clear(); } },
		},
	};
	
</script>